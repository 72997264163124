import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
	MenuOutlined,
	UserOutlined,
	ShoppingCartOutlined,
	HeartOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Drawer, Button } from "antd";
import "./Layout.css";
import logo from "./../../images/logo.svg";
import FooterComponent from "./Footer";
import { useAuth } from "../utils/useAuth";

const { Header, Footer } = Layout;

const Main = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [openKeys, setOpenKeys] = useState([]);
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [categories, setCategories] = useState([]);
	const { apiurl } = useAuth();

	const defaultSelectedKeys = () => {
		const pathname = location.pathname;
		const menuItems = [
			"/catalogue",
			"/activities",
			"/subscriptions",
			"/cart",
			"/wishlist",
			"/profile",
		];
		const index = menuItems.findIndex((item) => pathname.includes(item));
		return index !== -1 ? [`${index + 2}`] : ["1"];
	};

	const showDrawer = () => {
		setDrawerVisible(true);
	};

	const onClose = () => {
		setDrawerVisible(false);
	};

	const fetchCategories = async () => {
		try {
			console.log("Fetching categories from:", `${apiurl}/categories/filters/`);
			const response = await fetch(`${apiurl}/categories/filters/`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (response.ok) {
				const data = await response.json();
				console.log("Fetched categories data:", data);
				const formattedCategories = formatCategories(data);
				setCategories(formattedCategories);
				console.log("Formatted categories:", formattedCategories);
			} else {
				console.error("Failed to fetch categories:", response.statusText);
			}
		} catch (error) {
			console.error("Error fetching categories:", error);
		}
	};

	const formatCategories = (categoriesData) => {
		const categoryMap = {};

		categoriesData.forEach((category) => {
			const { name, type, items } = category;

			if (!categoryMap[name]) {
				categoryMap[name] = {
					value: name,
					label: name,
					children: [],
				};
			}

			if (!type) {
				items.forEach((item) => {
					if (item.name === name) {
						categoryMap[name].value = item.id;
						categoryMap[name].id = item.id;
					} else {
						categoryMap[name].children.push({
							value: item.id,
							label: item.name,
							main_category: name,
							id: item.id,
						});
					}
				});
			} else {
				let typeNode = categoryMap[name].children.find(
					(child) => child.label === type
				);

				if (!typeNode) {
					typeNode = {
						value: type,
						label: type,
						children: [],
					};
					categoryMap[name].children.push(typeNode);
				}

				items.forEach((item) => {
					typeNode.children.push({
						value: item.id,
						label: item.name,
						main_category: name,
						type: type,
						id: item.id,
					});
				});
			}
		});

		return Object.values(categoryMap);
	};

	const handleCategorySelect = (selectedCategories) => {
		const categoryData = selectedCategories;
		if (location.pathname === "/catalogue") {
			navigate("/catalogue", { state: { categoryData }, replace: true });
		} else {
			navigate("/catalogue", { state: { categoryData } });
		}
		setDrawerVisible(false);
	};

	useEffect(() => {
		fetchCategories();
	}, []);

	const menuItems = [
		{
			key: "1",
			label: <Link to="/">Home</Link>,
		},
		{
			key: "2",
			label: (
				<span>
					<Link to="/catalogue">Catalogue</Link>
				</span>
			),
			children: categories.map((mainCategory) => {
				if (mainCategory.id) {
					return {
						key: mainCategory.label,
						label: (
							<span onClick={() => handleCategorySelect([mainCategory.value])}>
								{mainCategory.label}
							</span>
						),
					};
				} else {
					return {
						key: mainCategory.label,
						label: mainCategory.label,
						children: mainCategory.children
							? mainCategory.children.map((subCategory) => ({
									key: subCategory.label,
									label: (
										<span
											onClick={() =>
												handleCategorySelect([
													mainCategory.value,
													subCategory.value,
												])
											}>
											{subCategory.label}
										</span>
									),

									children: subCategory.children
										? subCategory.children.map((item) => ({
												key: item.label,
												label: (
													<span
														onClick={() =>
															handleCategorySelect([
																mainCategory.value,
																subCategory.value,
																item.value,
															])
														}>
														{item.label}
													</span>
												),
										  }))
										: null,
							  }))
							: null,
					};
				}
			}),
		},
		{
			key: "3",
			label: <Link to="/activities">Activities</Link>,
		},
		{
			key: "4",
			label: <Link to="/subscriptions">Subscription Plans</Link>,
		},
		{
			key: "5",
			icon: <ShoppingCartOutlined />,
			label: <Link to="/cart">Cart</Link>,
		},
		{
			key: "6",
			icon: <HeartOutlined />,
			label: <Link to="/wishlist">WishList</Link>,
		},
		{
			key: "7",
			icon: <UserOutlined />,
			label: <Link to="/profile">My Account</Link>,
		},
	];
	
	return (
		<Layout>
			<Header className="user-layout-head">
				<div className="user-layout-header-logo">
					<a href="/">
						<img alt="logo" src={logo}></img>
					</a>
				</div>
				<Button
					className="user-layout-mobile-menu-button"
					type="text"
					icon={<MenuOutlined />}
					onClick={showDrawer}
				/>
				<Menu
					mode="horizontal"
					theme="light"
					defaultSelectedKeys={defaultSelectedKeys()}
					className="user-layout-menu"
					items={menuItems}
				/>
			</Header>
			<Drawer
				title="Menu"
				placement="left"
				onClose={onClose}
				open={drawerVisible}>
				<Menu
					mode="inline"
					theme="light"
					inlineCollapsed = {false}
					defaultSelectedKeys={defaultSelectedKeys()}
					openKeys={openKeys}
					onOpenChange={(keys) => setOpenKeys(keys)}
					items={menuItems}
				/>
			</Drawer>
			<Layout>
				<div className="user-layout-content">
					{children}
					<FooterComponent />
				</div>
			</Layout>
		</Layout>
	);
};

export default Main;
